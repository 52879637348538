.App, .LayerGroups {
  height: 100vh;
  background: rgb(38, 33, 33);
}

.control-strip {
  background: rgba(0, 0, 0, 0.75);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  position: absolute;
  top: 0; bottom: 0; left: 0;
  width: 100px;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.3);
}

.LayerGroups {
  grid-column: 1 / -1;
  display: grid;
  grid-gap: 0;/*calc(var(--gutter) / 2);*/
  grid-auto-flow: column;

  overflow-x: scroll;
  scroll-snap-type: x proximity;
  margin-bottom: 0;
}

.LayerGroups > .layer-scroll:first-of-type {
  padding-left: 50vw;
}

.mode-edit .LayerGroups > .layer-scroll:first-of-type  {
  padding-left: 100px;
}

.control-strip button {
  display: block;
  margin: 12px;
  width: 76px;
  padding: 6px;
  border: 3px solid #36383f;
  background: #36383f;
  font-family: 'Lato', 'Lucida Grande', 'Lucida Sans Unicode', Tahoma, Sans-Serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  line-height: 1.2;
  position: relative;
  border-radius: 4px;
  background: #36383f;
  color: white;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.control-strip button:hover {
  background: #4e505a;
}

.control-strip button:active {
  transform: translateY(1px);
}

.control-strip .combine {
  position: absolute;
  bottom: 0;
}

.combine header {
  color: #ccc;
  text-align: center;
}
