body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.layer-scroll {
  min-width: 500px;
  overflow: scroll;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.layer-view {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  min-height: 100vh;
}

.layer-view ul {
  margin: auto 0; padding: 0;
}

.layer-view li {
  list-style-type: none;
  margin: 2em;
}


.layer-view textarea {
  background: transparent;
  color: white;
  font-size: 16pt;
  border: none;
  text-decoration: underline;
  outline: none;
  resize: none;
  width: 93%;
  height: 20px;
  padding: 12px;
  margin: -12px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.has-selection textarea {
  color: #777;
}
.has-selection textarea.selected-path {
  color: white;
}
.has-selection textarea.selected-parent {
  color: #bbb;
}

.LayerGroups > .layer-scroll:first-of-type .node textarea {
    text-align: center;
}

.node a {
  border-radius: 24px;
  height: 24px;
  width: 24px;
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  line-height: 22px;
  font-weight: bold;
  border: 2px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  float: right;
  margin-top: 1px;
  margin-right: -16px;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  -webkit-transition: border 0.2s;
  transition: border 0.2s;
}

.node a:hover {
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.75);
}

.node a.new {
  color: hsl(200, 100%, 80%);
  border: 2px solid hsla(200, 100%, 80%, 0.5);
}

.node a.new:hover {
  border: 2px solid hsla(200, 100%, 80%, 1.0) !important;
}
.panel {
    position: fixed;
    top: 0; left: 0; right: 50%; bottom: 0;
    z-index: 1000;
    overflow: scroll;
    background: rgba(255, 255, 255, 0.95);
    border-right: 1px solid white;
    color: black;
    box-shadow: 0 0 10px 4px black;
}

.panel.open {
    display: block;
}

.panel.closed {
    display: none;
}

.panel .content {
    margin-left: 115px;
    padding: 15px;
    overflow-y: scroll;
    height: calc(100vh - 30px);
}

.panel .control-strip {
    background: #111;
}

.panel h2 {
    margin: 0;
    margin-top: 4px;
    text-align: center;
}

.open-panel li {
    list-style-type: none;
    border-radius: 8px;
}

.open-panel li:nth-child(odd) {
    background-color: #eee;
}

.open-panel li a {
    display: block;
    margin: 8px;
    padding: 1em;
    font-size: 12pt;
    cursor: pointer;
}

.open-panel li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.open-panel h1 {
    text-align: center;
}
.open-panel ul {
    margin-top: 2em;
    padding: 0;
}

.panel button {
  display: block;
  margin: 12px;
  width: 76px;
  padding: 6px;
  border: 3px solid #36383f;
  background: #36383f;
  font-family: 'Lato', 'Lucida Grande', 'Lucida Sans Unicode', Tahoma, Sans-Serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  line-height: 1.2;
  position: relative;
  border-radius: 4px;
  background: #36383f;
  color: white;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.panel button:hover {
    background: #4e505a;
}

.panel button:active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
}

.panel .left { float: left; }
.panel .right { float: right; }

.open-panel .modified {
    display: inline-block;
    margin-left: 12px;
    color: #999;
}

.combine-panel .root-sentence, .combine-panel .story {
    font-size: 14pt;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #e6e6e6;
}

.root-sentence {
    background-color: hsla(206, 70%, 92%, 1) !important;
    font-size: 16pt;
}

.combine-panel .explanation button {
    display: inline-block;
    width: 100px;
    background: #0090ff;
    line-height: 1.5em;
    margin: 0;
    float: right;
    border: 3px solid #2145af;
    margin-right: 10px;
    margin-left: 25px;
}

.combine-panel .explanation button:hover {
    background: #29a2ff;
}

.combine-panel .explanation {
    color: #888;
    font-size: 12px;
}

.combine-panel pre {
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    font-size: 13pt;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin-right: 20px;
}

/* FIXME this should probably go elsewhere */
.combine-mode .node .selected {
    background-color: rgba(0, 144, 255, 0.9);
    border-radius: 12px;
}

.combine-panel button.active {
    border: 2px solid white;
}
.App, .LayerGroups {
  height: 100vh;
  background: rgb(38, 33, 33);
}

.control-strip {
  background: rgba(0, 0, 0, 0.75);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  position: absolute;
  top: 0; bottom: 0; left: 0;
  width: 100px;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.3);
}

.LayerGroups {
  grid-column: 1 / -1;
  display: grid;
  grid-gap: 0;/*calc(var(--gutter) / 2);*/
  grid-auto-flow: column;

  overflow-x: scroll;
  -webkit-scroll-snap-type: x proximity;
      -ms-scroll-snap-type: x proximity;
          scroll-snap-type: x proximity;
  margin-bottom: 0;
}

.LayerGroups > .layer-scroll:first-of-type {
  padding-left: 50vw;
}

.mode-edit .LayerGroups > .layer-scroll:first-of-type  {
  padding-left: 100px;
}

.control-strip button {
  display: block;
  margin: 12px;
  width: 76px;
  padding: 6px;
  border: 3px solid #36383f;
  background: #36383f;
  font-family: 'Lato', 'Lucida Grande', 'Lucida Sans Unicode', Tahoma, Sans-Serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  line-height: 1.2;
  position: relative;
  border-radius: 4px;
  background: #36383f;
  color: white;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.control-strip button:hover {
  background: #4e505a;
}

.control-strip button:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.control-strip .combine {
  position: absolute;
  bottom: 0;
}

.combine header {
  color: #ccc;
  text-align: center;
}

