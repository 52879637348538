.combine-panel .root-sentence, .combine-panel .story {
    font-size: 14pt;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #e6e6e6;
}

.root-sentence {
    background-color: hsla(206, 70%, 92%, 1) !important;
    font-size: 16pt;
}

.combine-panel .explanation button {
    display: inline-block;
    width: 100px;
    background: #0090ff;
    line-height: 1.5em;
    margin: 0;
    float: right;
    border: 3px solid #2145af;
    margin-right: 10px;
    margin-left: 25px;
}

.combine-panel .explanation button:hover {
    background: #29a2ff;
}

.combine-panel .explanation {
    color: #888;
    font-size: 12px;
}

.combine-panel pre {
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    font-size: 13pt;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin-right: 20px;
}

/* FIXME this should probably go elsewhere */
.combine-mode .node .selected {
    background-color: rgba(0, 144, 255, 0.9);
    border-radius: 12px;
}

.combine-panel button.active {
    border: 2px solid white;
}