.panel {
    position: fixed;
    top: 0; left: 0; right: 50%; bottom: 0;
    z-index: 1000;
    overflow: scroll;
    background: rgba(255, 255, 255, 0.95);
    border-right: 1px solid white;
    color: black;
    box-shadow: 0 0 10px 4px black;
}

.panel.open {
    display: block;
}

.panel.closed {
    display: none;
}

.panel .content {
    margin-left: 115px;
    padding: 15px;
    overflow-y: scroll;
    height: calc(100vh - 30px);
}

.panel .control-strip {
    background: #111;
}

.panel h2 {
    margin: 0;
    margin-top: 4px;
    text-align: center;
}

.open-panel li {
    list-style-type: none;
    border-radius: 8px;
}

.open-panel li:nth-child(odd) {
    background-color: #eee;
}

.open-panel li a {
    display: block;
    margin: 8px;
    padding: 1em;
    font-size: 12pt;
    cursor: pointer;
}

.open-panel li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.open-panel h1 {
    text-align: center;
}
.open-panel ul {
    margin-top: 2em;
    padding: 0;
}

.panel button {
  display: block;
  margin: 12px;
  width: 76px;
  padding: 6px;
  border: 3px solid #36383f;
  background: #36383f;
  font-family: 'Lato', 'Lucida Grande', 'Lucida Sans Unicode', Tahoma, Sans-Serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  line-height: 1.2;
  position: relative;
  border-radius: 4px;
  background: #36383f;
  color: white;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.panel button:hover {
    background: #4e505a;
}

.panel button:active {
    transform: translateY(1px);
}

.panel .left { float: left; }
.panel .right { float: right; }

.open-panel .modified {
    display: inline-block;
    margin-left: 12px;
    color: #999;
}
