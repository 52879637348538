.layer-scroll {
  min-width: 500px;
  overflow: scroll;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.layer-view {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100vh;
}

.layer-view ul {
  margin: auto 0; padding: 0;
}

.layer-view li {
  list-style-type: none;
  margin: 2em;
}


.layer-view textarea {
  background: transparent;
  color: white;
  font-size: 16pt;
  border: none;
  text-decoration: underline;
  outline: none;
  resize: none;
  width: 93%;
  height: 20px;
  padding: 12px;
  margin: -12px;
  transition: color 0.3s;
}

.has-selection textarea {
  color: #777;
}
.has-selection textarea.selected-path {
  color: white;
}
.has-selection textarea.selected-parent {
  color: #bbb;
}

.LayerGroups > .layer-scroll:first-of-type .node textarea {
    text-align: center;
}

.node a {
  border-radius: 24px;
  height: 24px;
  width: 24px;
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  line-height: 22px;
  font-weight: bold;
  border: 2px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  float: right;
  margin-top: 1px;
  margin-right: -16px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s;
  transition: border 0.2s;
}

.node a:hover {
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.75);
}

.node a.new {
  color: hsl(200, 100%, 80%);
  border: 2px solid hsla(200, 100%, 80%, 0.5);
}

.node a.new:hover {
  border: 2px solid hsla(200, 100%, 80%, 1.0) !important;
}